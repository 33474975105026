import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SectionTitle from "../components/sectiontitle";
import Layout from "../components/layout";
import SEO from "../components/seo";


export default function( {data} ) {
    return (
        <Layout>
            <SEO lang="en" title="Making art" />
            <section id="art" className="container" style={{ minHeight: "600px" }}>
                <div className="section-title body-max-readable">
                    <SectionTitle title="Making art" />

                        <h3>Vision</h3>

                        <p className="text-primary">My current digital fine art artwork explores the 
                            concept of a “natural field theory.” A basic premise is that when you 
                            view the world around you, the rods and cones in your eyes are only 
                            detecting one dimension of the energy present in the environment—
                            that of reflected light that ranges through the visible spectrum. 
                            Living things and minerals may radiate energy in other fields that 
                            operate in a different temporal scale, and/or in wavelengths outside 
                            human vision. Just as infrared film allows us to see a heat-field 
                            view of things, the natural field concept explores a vision of the 
                            natural world that fuses reflected light with an envisioning of 
                            other energy fields.
                        </p>

                        <h3>Inspiration</h3>

                        <p className="text-primary">Many of my works are inspired by the incredible 
                            range of textures and colors in nature, the spiritual impact of sacred 
                            places, and/or a lifelong fascination with maps and geography. Seeing 
                            other artists’ works at galleries or open studios (or better 
                            yet artists at work) is also inspiring.
                        </p>

                        <div className="center pad-over-and-under">
                            <Img fluid={data.fileName.childImageSharp.fluid} alt="An example 
                            artwork from the over 1000 created by the Art of Geography" />
                            <div className=" pad-above-10px"><span className="aog-caption">Dehradun 
                            Canyon's incision in the temporal projector</span></div>
                        </div>
                        

                        <h3>Process</h3>

                        <p className="text-primary">Some artists start an artwork with a vision 
                            of the end result in mind. Others start working with no particular 
                            goal, engaging in a voyage of discovery and exploration. For them, 
                            it is the serendipitous accidents that result in the most compelling 
                            work. So my process begins with setting up the right environment for 
                            serendipitous accidents to happen, and then trying to get out of the 
                            way. </p>
                            
                        <p className="text-primary">After working awhile with a canvas, sometimes I am lucky to get 
                            to a point of transcendence where the painting stops being a painting, 
                            and becomes a totally believable world that I can imagine being in. 
                            Beyond that, there are turning points when suddenly the artwork 
                            becomes something I would have never anticipated, planned, or thought 
                            of. Those moments where something amazing is revealed are the highest 
                            point of the creative process. As Alberto Giacometti said, 
                            “The object of art is not to reproduce reality, but to create a 
                            reality of the same intensity.”
                        </p>

                        <p className="text-primary">Working with digital media allows for exploring 
                            many alternate paths for one artwork. Whereas a traditional media artist 
                            throws away possibilities with every creative decision until the final 
                            arrangement dries on canvas, I explore many of the alternate paths, 
                            ending up with dozens of versions of the artwork. Usually one of the 
                            versions feels like it best exemplifies the vision mentioned above.
                        </p>

                        <p className="text-primary">One of the things that has helped me is to 
                            develop a “System of the World” that guides your creative efforts 
                            (with due credit to Neal Stephenson’s book title). I see a system 
                            of the world including your inspiration, filters on what tools/
                            methods you use, and some sort of framework that ties it all 
                            together. For instance, most of my artwork is inspired by my 
                            passion for mountains, wilderness, and maps. There’s underlying 
                            structures that tie all my works together. I like to layer many 
                            different treatments together, and I also like to develop two 
                            canvases in parallel from the same inspiration, and something 
                            about that is freeing and allows one of them to be a disaster 
                            without ruining the other idea. 
                        </p>

                        <p className="text-primary">Apologies if I haven’t been able to answer 
                        your questions in a timely way. I am not a full-time artist so my spare 
                        time is rationed out amongst lots of threads.  
                        </p>

                        <h3>Tools</h3>

                        <p className="text-primary">I use a Wacom tablet with Synthetik 
                            Studio Artist and Photoshop.
                        </p> 

                        <h3>Other</h3>
                        
                        <p className="text-primary">My last exhibit was at the wonderful 
                            Point B Studio in Port Orford, OR.
                        </p>

                        <p className="text-primary">Ideas often come to me while doing 
                            unrelated things like riding the bus or bicycle. I read voraciously 
                            both fiction, non-fiction & articles which also percolate into facets 
                            like naming the artworks. Also my work is informed by having spent 
                            countless hours capturing the sense of place using cartography and 
                            photography. 
                        </p>

                        <p className="text-primary">I live & work in Portland, Oregon. </p>

                        <p>&nbsp; </p>
                        <div className="align-left">
                            <a href="/portfolio/art" className="btn">
                                View art portfolio
                            </a>
                        </div>


                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "unstrata-1280px.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280, maxHeight: 749) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`